var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('van-form',{attrs:{"label-width":"130px","label-align":"right"},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"company","label":"公司名称","placeholder":"请输入公司名称","rules":[
        { required: true, message: '公司名称不能为空' },
        { validator: _vm.v_company_len, message: '公司名称必须在1~50个字符之间' },
      ]},model:{value:(_vm.company.company),callback:function ($$v) {_vm.$set(_vm.company, "company", $$v)},expression:"company.company"}}),_c('van-field',{attrs:{"type":"text","name":"name","label":"联系人姓名","placeholder":"请输入联系人姓名","rules":[
        { required: true, message: '联系人姓名不能为空' },
        { validator: _vm.v_name_len, message: '联系人姓名必须在1~20个字符之间' },
      ]},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('van-row',[_c('van-col',{attrs:{"span":"18"}},[_c('van-field',{attrs:{"type":"text","name":"phone","label":"联系人手机","placeholder":"请输入联系人手机","rules":[
            { required: true, message: '联系人手机不能为空' },
            {
              validator: _vm.v_phone_check,
              message: '请输入正确的手机号',
            },
          ]},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}})],1),_c('van-col',{attrs:{"span":"5"}},[(_vm.company.button_show)?_c('van-button',{staticClass:"sendmsg",attrs:{"native-type":"button","type":"info","size":"small"},on:{"click":_vm.sendMsg}},[_vm._v(" 发送验证码 ")]):_vm._e(),(_vm.company.second_show)?_c('van-button',{staticClass:"sendmsg",attrs:{"native-type":"button","type":"info","size":"small","disabled":""}},[(_vm.company.second_show)?_c('van-count-down',{staticStyle:{"color":"#fff","padding":"0 15px"},attrs:{"time":60000,"format":"ss秒"},on:{"finish":_vm.finish}}):_vm._e()],1):_vm._e()],1)],1),_c('van-field',{attrs:{"type":"text","name":"手机验证码","label":"手机验证码","maxlength":6,"placeholder":"请输入手机验证码","rules":[{ required: true, message: '验证码不能为空' }]},model:{value:(_vm.company.code),callback:function ($$v) {_vm.$set(_vm.company, "code", _vm._n($$v))},expression:"company.code"}}),_c('van-field',{attrs:{"name":"uploader","label":"上传营业执照照片","rules":[{ required: true, message: '图片不能为空' }]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('upload-img',{ref:"business_license",attrs:{"images":_vm.company.business_license,"maxCount":1}})]},proxy:true}])}),_c('van-field',{staticStyle:{"height":"110px"},attrs:{"type":"textarea","name":"申请理由描述","label":"申请理由描述","placeholder":"请输入200字以内的申请理由描述,不少于10字","rules":[
        { required: true, message: '申请理由不能为空' },
        {
          validator: _vm.v_describe,
          message: '申请理由必须在10~200个字符之间',
        },
      ]},model:{value:(_vm.company.describe),callback:function ($$v) {_vm.$set(_vm.company, "describe", $$v)},expression:"company.describe"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info"}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }