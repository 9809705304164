<template>
  <div>
    <h3 class="title">{{ title }}</h3>
    <van-form @submit="onSubmit" label-width="130px" label-align="right">
      <van-field
        v-model="company.company"
        name="company"
        label="公司名称"
        placeholder="请输入公司名称"
        :rules="[
          { required: true, message: '公司名称不能为空' },
          { validator: v_company_len, message: '公司名称必须在1~50个字符之间' },
        ]"
      />
      <van-field
        v-model="company.name"
        type="text"
        name="name"
        label="联系人姓名"
        placeholder="请输入联系人姓名"
        :rules="[
          { required: true, message: '联系人姓名不能为空' },
          { validator: v_name_len, message: '联系人姓名必须在1~20个字符之间' },
        ]"
      />

      <van-row>
        <van-col span="18">
          <van-field
            v-model="company.phone"
            type="text"
            name="phone"
            label="联系人手机"
            placeholder="请输入联系人手机"
            :rules="[
              { required: true, message: '联系人手机不能为空' },
              {
                validator: v_phone_check,
                message: '请输入正确的手机号',
              },
            ]"
        /></van-col>
        
        <van-col span="5">
          <van-button
            native-type="button"
            type="info"
            size="small"
            class="sendmsg"
            @click="sendMsg"
            v-if="company.button_show"
          >
            发送验证码
          </van-button>
          <van-button
            native-type="button"
            type="info"
            size="small"
            class="sendmsg"
            disabled
            v-if="company.second_show"
          >
            <van-count-down
              :time="60000"
              format="ss秒"
              style="color: #fff; padding: 0 15px"
              @finish="finish"
              v-if="company.second_show"
            />
          </van-button>
        </van-col>
      </van-row>

      <van-field
        v-model.number="company.code"
        type="text"
        name="手机验证码"
        label="手机验证码"
        :maxlength="6"
        placeholder="请输入手机验证码"
        :rules="[{ required: true, message: '验证码不能为空' }]"
      />
      <van-field
        name="uploader"
        label="上传营业执照照片"
        :rules="[{ required: true, message: '图片不能为空' }]"
      >
        <template #input>
          <upload-img
            ref="business_license"
            :images="company.business_license"
            :maxCount="1"
          ></upload-img>
        </template>
      </van-field>

      <van-field
        v-model="company.describe"
        type="textarea"
        name="申请理由描述"
        label="申请理由描述"
        placeholder="请输入200字以内的申请理由描述,不少于10字"
        style="height: 110px"
        :rules="[
          { required: true, message: '申请理由不能为空' },
          {
            validator: v_describe,
            message: '申请理由必须在10~200个字符之间',
          },
        ]"
      />

      <div style="margin: 16px">
        <van-button round block type="info">提交</van-button>
      </div>
    </van-form>
	
  </div>
</template>

<script>
import { CellGroup } from "vant";
import UploadImg from "../../components/UploadImg";
import { micro_post } from "../../Http/api";
import { Notify,Toast } from "vant";
import { NO1ShouQuans } from '../../utils/impower.js'

export default {
  components: { UploadImg },
    metaInfo(){
            return {
                 title: this.$route.query.shopId == '1078' ? '移动端拆船件' :    window.localStorage.getItem('getShopName') 
            }
        },
	data: function () {
		return {
			company: {
				name: "",
				company: "",
				phone: "",
				code: "",
				business_license: [],
				uploader: "",
				describe: "",
				shopId: "",
				button_show: true,
				second_show: false,
			},
		
		};
	},
  methods: {

    v_company_len(val) {
      return /.{1,50}/.test(val);
    },
    v_name_len(val) {
      return /.{1,20}/.test(val);
    },
    v_phone_check(val) {
      return /^1[34578]\d{9}$/.test(val);
    },
    v_describe(val) {
      return /.{10,200}/.test(val);
    },
    note(){
      
		let data = {
			url:"",
			params:{phone:this.company.phone}
		}
		micro_post(data,this);
		success: (res) => {
			console.log(res)
		}
    },
    onSubmit(values) {
		
		this.company.business_license =
		this.$refs.business_license.imagesUrlList[0];
		this.company.shopId = this.$route.query.shopId;
		const type = this.$route.query.type;
		this.company.type = type;

		if (type !== "1" && type !== "2" && type !== "3") {
			this.$toast.fail("非法操作")
			return;
		} 
	
		const data = {
			url: "/index.php/BidManagement/apply",
			params: this.company,
			success: (data) => {
				if(data.code == 0){
					this.$router.push({path:'/applyFor',query:{shopId:this.$route.query.shopId,type:this.$route.query.type}})
				}

			}
		};
		micro_post(data,this);
    },
    sendMsg() {
      let phone = this.company.phone;
     
      if (!phone) {
        this.$toast.fail("手机号不能为空");
        return;
      }else{
        this.company.button_show = false;
        this.company.second_show = true;
      }
      var isPhone = this.v_phone_check(phone);
      if (!isPhone) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      const data = {
        url: "/index.php/BidManagement/send_msg",
        params: { phone },
        success: (data) => {
          Notify({
            message: data.msg,
            duration: 1000,
            type: "success",
          });
         
        },
      };
      micro_post(data,this);
    },
    finish() {
      this.company.button_show = true;
      this.company.second_show = false;
    },
    // afterRead(file) {
    //   file.status = "uploading";
    //   file.message = "上传中...";

    //   setTimeout(() => {
    //     file.status = "failed";
    //     file.message = "上传失败";
    //   }, 1000);
    // },
	getData(){
		 const data = {
			url: "/index.php/BidManagement/find_company",
			params: { shopId: this.$route.query.shopId,type:this.$route.query.type },
			success: (res) => {
				if (res.data) {
				this.company.name = res.data.name;
				this.company.company = res.data.company;
				this.company.phone = res.data.phone;
				this.company.code = res.data.code;
				this.company.uploader = res.data.uploader;
				this.company.describe = res.data.describe;
				let business_licenses = res.data.business_license;
				let obj = {
					url: business_licenses,
				};
				this.$refs.business_license.images = [obj];
				}
			},
			};
			micro_post(data,this);
	}
  },
  computed: {
    title() {
      const type = this.$route.query.type;
      switch (type) {
        case "1":
          return "大宗竞价资格申请";
          break;
        case "2":
          return "大宗回用件竞价";
          break;
        case "3":
          return "回用件竞价资格申请";
          break;

        default:
          break;
      }
    },
  },
  async created() {

	// if(this.$route.query.code){
	// 	this.$http.post('/index.php/index/wechat.bid_wechat/redirect_uri',{url:window.location.href,shopId:this.$route.query.shopId}).then(res=>{
	// 		console.log(res.data)
	// 		if(res.data.code == 0) {
	// 			this.getData()
	// 		}else if(res.data.code == 2){
	// 			window.location.href = res.data.data
	// 		}else{
	// 			this.$toast.fail(res.data.msg)
	// 		}
			
	// 	}) 
	// }else{
		// }
    
    const toast = Toast.loading({
          duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载中..."
        });
        await NO1ShouQuans(this)
        toast.clear()//清除加载效果
      this.getData()

		

   
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-size: 28px;
}
.van-button__text{
  font-size: 14px;
}
</style>